.landing-page {

}

.landing-page .header {
    border-bottom : solid 1px rgba(69, 69, 69, .1);
}

.landing-page .content {
    overflow-y : scroll;
    padding    : 0 !important;
    margin     : 0 !important;
}

.landing-page .content .error {
    font-size   : 3.0vw;
    color       : red;
    font-weight : bold;
}

.landing-page .content .welcome {
    font-size : 3.0vw;
}

.landing-page .content .welcome .name {
    font-weight : bold;
}

.landing-page .content .message {
    font-size     : 1.5vw;
    font-weight   : bold;
    margin-bottom : 25px;
}

.landing-page .content .description {
    font-size     : 1.3vw;
    margin-top    : 25px;
    border        : solid 1px rgba(230, 231, 233, 1);
    border-radius : 4px;
    background    : rgba(230, 231, 233, 1);
    padding       : 20px;

}

.landing-page .content .assessment-button {
    font-size      : 1.5vw;
    margin         : 4px 100px;
    background     : var(--smartpcmh-green);
    color          : white;
    padding        : 5px 6px;
    box-shadow     : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition     : background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius  : 4px;
    font-weight    : 500;
    line-height    : 1.75;
    letter-spacing : 0.02857em;
    text-transform : uppercase;
    font-family    : "Roboto", "Helvetica", "Arial", sans-serif;
    cursor         : pointer;
}


.landing-page .header .branding {
    display : block;
}

.landing-page .header .branding img {
    display : block;
    width   : 100%;
    /*background : #f5f5f8;*/
    margin  : 15px auto;
    padding : 0 10px;
}