@import url("../../fonts/fonts.css");

.assessment .header {
    font-family : 'Acumin Pro', sans-serif !important;
    background  : var(--smartpcmh-alt-black);
}

.assessment .header .errors {
    color       : red !important;
    font-weight : bold;
    text-align  : center;
    background  : white;
    font-size   : 1.4vw;
}

.assessment .header .completed {
    background  : var(--smartpcmh-green);
    padding     : 6px 0 6px 4px;
    font-weight : bold;
}

.assessment .header .dates {
    color     : white;
    font-size : 16px;
}

.assessment .header .dates .date-header {
    font-family : "Acumin Pro", inherit, sans-serif;
    font-weight : 500;
}

.assessment .header .dates .date-value {
    font-family : "Acumin Pro", inherit, sans-serif;
}

.assessment .header .assessment-name {
    font-family : "Acumin Pro", inherit, sans-serif;
    color       : white;
    font-size   : 1.3vw;
}

.assessment .header .status {
    padding            : 4px;
    border-radius      : 6px;
    -webkit-box-shadow : 4px 4px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow    : 4px 4px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow         : 4px 4px 6px 0 rgba(0, 0, 0, 0.16);
}

.assessment .header .person-name {
    font-family : "Acumin Pro", inherit, sans-serif;
    font-size   : 1.8vw;
    color       : white !important;
}

.assessment .header .completed-assessment-icon {
    color     : var(--smartpcmh-button-border);
    font-size : 1.8vw;

}

.assessment .header .editorButton {
    margin         : 4px 2px;
    width          : 150px;
    background     : var(--smartpcmh-green);
    color          : white;
    padding        : 5px 6px;
    box-shadow     : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition     : background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius  : 4px;
    font-weight    : 500;
    line-height    : 1.75;
    letter-spacing : 0.02857em;
    text-transform : uppercase;
    font-family    : "Roboto", "Helvetica", "Arial", sans-serif;
    font-size      : 0.875rem;
}

.assessment .header .branding {
    display : block; }

.assessment .header .branding img {
    display : block;
    width   : 100px;
    /*background : #f5f5f8;*/
    /*margin     : 15px auto;*/
}


.assessment .FormControlLabel > span {
    font-size : 16px;
}

.assessment .FormControlLabel > .Radio {
    margin-left : 20px;
    color       : var(--smartpcmh-alt-green-dark);
    padding     : 0
}

.assessment .FormControlLabel > .Radio.read-only {
    color : var(--smartpcmh-alt-black)
}

.assessment .FormControlLabel > .Radio.checked {
    color : var(--smartpcmh-alt-green-dark);
}

.FormControlLabel > .Checkbox {
    margin-left : 20px;
    color       : var(--smartpcmh-alt-green-dark);
    padding     : 0
}

.assessment .FormControlLabel > .Checkbox.read-only {
    color : var(--smartpcmh-alt-black);
}

.FormControlLabel > .Checkbox.checked {
    color : var(--smartpcmh-alt-green-dark);
}


.assessment .assessment-contents .groups {
    position   : absolute;
    overflow-y : scroll;
    height     : 85%;
    width      : 100%;
    /*top        : 140px;*/


}

.assessment .assessment-contents .group-control {
    font-weight : bold;
    font-size   : 22px;
    padding     : 10px 2px;
}

.assessment .assessment-contents .group-control .icon {
    cursor  : pointer;
    outline : none;
}

.assessment .assessment-contents .group {
    padding       : 4px 16px;
    background    : var(--smartpcmh-line-color);
    margin        : 40px 10px;
    border-radius : 6px;
}

.assessment .assessment-contents .group .question {
    background-color : #fff !important;
    box-shadow       : 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border           : 1px solid #dee2e6;
    border-radius    : 0.25rem !important;

}

.assessment .assessment-contents .group .read-only {
    background    : var(--smartpcmh-line-color) !important;
    box-shadow    : none !important;
    border        : none !important;
    border-radius : 0.25rem !important;

}


.assessment .assessment-contents .questions {
    margin : 2px;
}


.assessment .question-error {
    border : 2px solid rgba(255, 0, 0, .50) !important;
}


main {
    padding : 0;
}