.selection {
    display     : flex;
    flex-flow   : row;
    flex-wrap   : wrap;
    align-items : flex-start;
}

.selection .answer {
    float : left;
    width : calc(100% / 2.2);
}